// =================================================
// === Browser reset and common elements styles ====
// =================================================
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: "Poppins";
}
html {
  //scroll-behavior: smooth;     //This is an auto smooth scrolling
}
ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
//--------------------Rremove highlight in mobile and ipad created by sachith (11/20/2020)-------------------
@include respond-to("medium_lg") {
  @include Rremove-highlight;
}
@include respond-to("medium") {
  @include Rremove-highlight;
}
@include respond-to("small") {
  @include Rremove-highlight;
}
//--------------------Remove text decorations for all <a> tags created by sachith (12/01/2020)---------------
// a {
//   text-decoration: none;
//   color: #7098d2;
// }

//--------------------Break line in member description created by sachith (11/19/2020)-----------------------
p {
  //white-space: pre-line;
  text-align: justify; //---------Justify the all <p> tages
  @include respond-to("small") {
    //white-space: normal;
  }
}

// =========================================
// ======== common element styles ===========
// =========================================

//***********************The common style for About Portfolio Contact Headings*****************************
.values-title {
  display: flex;
  align-items: center;
  span {
    width: 7px;
    height: 57.1px;
    border-radius: 50px;
    background-color: #55bbac;
    display: block;
    @include respond-to("small") {
      display: none;
    }
  }
  h1,
  h2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 0.75;
    letter-spacing: 1.73px;
    color: #ffffff;
    margin-left: 35px;
    @include respond-to("medium_lg") {
      font-size: 44px;
    }
    @include respond-to("medium") {
      font-size: 31px;
    }
    @include respond-to("small") {
      margin-left: 0;
      font-size: 36px;
      line-height: 1.3;
    }
  }
}

.sub-heaading {
  display: flex;
  align-items: center;
  span {
    width: 7px;
    height: 30.6px;
    border-radius: 50px;
    background-color: #ffffff;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.86px;
    color: #ffffff;
    margin-left: 26px;
  }
}

//***********************The common style for card containar*****************************
.common_card_containar {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  transition: 0.3s;
  margin: 44px 10px;
  background-color: #ffffff;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  position: relative;
  @include respond-to("medium_lg") {
    margin: 44px 5px;
  }
  @include respond-to("small") {
    margin: 44px 0;
  }
  .image_containar {
    border-radius: 12px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(331deg, #30567c, #356089);
    position: absolute;
    height: 150px;
    top: -12px;
    left: -12px;
    width: 168px;
    @include respond-to("small") {
      top: -12px;
      left: -12px;
      width: 120px;
      height: 120px;
    }
    .image_icon {
      position: absolute;
      top: 20%;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 99;
    }
  }
  .leftside_text {
    display: flex;
    padding-left: 24%;
    min-height: 127px;
    align-items: center;
    @include respond-to("medium") {
      padding-left: 32%;
    }

    .card_list_items {
      font-size: 16px;
      padding: 0 75px;
      @include respond-to("medium") {
        padding: 0 75px;
      }
      .item {
        display: flex;
        //padding: 12px 0;
        margin: 20px auto;
        .image_icon {
          width: 4%;
          @include respond-to("medium_lg") {
            width: 6%;
          }
          @include respond-to("medium") {
          }
          @include respond-to("small") {
            width: 10%;
          }
        }
        p {
          margin-left: 8%;
          color: #000000;
          letter-spacing: 0.58px;
          font-size: 16px;
          font-weight: normal;
          text-align: left;
          @include respond-to("medium") {
            font-size: 13px;
          }
          ul {
            list-style: none;
          }
        }
      }
    }
  }
  //-------download icon card style--------
  .download_icon {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99;
    transition: 0.3s;
  }
  &:hover {
    box-shadow: 0 0 24px 12px #142c44;
    transform: scale(1.05);
    .download_icon {
      transform: scale(1.3);
    }
  }
}

//************The common style used for Main Headings in Home page************
.section-title {
  margin: 30px 0;
  @include respond-to("small") {
    margin: 30px 40px;
  }
  h2 {
    @include HeadingTitle;
    @include respond-to("small") {
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.86px;
    }
  }
}

//**************** About common card padding style *****************
.common_card {
  margin: auto 35px;

  .about_leftside_text {
    padding-left: 20%;
    @include respond-to("medium") {
      padding-left: 30%;
    }
    .about-item {
      padding: 0 40px;
      @include respond-to("medium_lg") {
        padding: 0 42px;
      }
    }
  }
}

.select-menu {
  position: absolute;
  // font-family: "Poppins", sans-serif;
  right: 10%;
  top: 9.5%;
  z-index: 99;
  @include respond-to("ex_large_max") {
    right: 10%;
    top: 12%;
  }
  @include respond-to("medium_lg") {
    right: 10%;
    top: 14%;
  }
  @include respond-to("medium") {
    right: 10%;
    top: 16%;
  }
  @include respond-to("small") {
    right: 20px;
    top: 17%;
    left: 43px;
  }

  //------------------Dropdown menu in portfolio page-----------------------------
  label {
    color: #ffffff;
    letter-spacing: 0.65px;
    font-size: 18px;
    font-weight: normal;
    select {
      @include respond-to("small") {
        background-color: #345d85;
        color: #ffffff;
      }
      margin-left: 20px;
      padding-left: 8%;
      background-color: #ffffff;
      font-family: "Poppins";
      font-size: 18px;
      letter-spacing: 0.65px;
      font-weight: 600;
      border: 0px;
      outline: 0px;
      border-radius: 8px;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
      width: 324px;
      height: 48px;
      cursor: pointer;
      //------------this is for arrow icon------------------------
      background-position: right 25px center;
      background-repeat: no-repeat;
      background-size: auto;
      color: #000000;
      appearance: none;
      @include respond-to("medium_lg") {
        width: 252px;
      }
      @include respond-to("medium") {
        width: 170px;
        font-size: 14px;
        margin-left: 10px;
      }
      @include respond-to("small") {
        width: 69%;
      }
      &:focus {
        outline: 0;
        border: 0;
      }
    }
    position: relative;
    option {
      font-size: 18px;
      border: none;
      outline: none;
      border-style: none;
      &:active {
        font-weight: 600;
      }
      &:focus {
        outline: 0;
        border: 0;
      }
    }
  }
  label:after {
    content: "\f078";
    font-family: "FontAwesome";
    font-size: 14px;
    color: #000000;
    right: 20px;
    top: 4px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    @include respond-to("small") {
      color: #ffffff;
      font-size: 12px;
    }
  }
  label:before {
    content: "";
    right: 4px;
    top: 0px;
    width: 23px;
    height: 18px;
    position: absolute;
    pointer-events: none;
    display: block;
  }
}

//------------This code used for the portfolio company card pop up-------------------
.popup-page {
  .App-header {
    @include fade_out;
  }
  footer {
    display: none;
  }
  .footer_mobile {
    display: none;
  }
}

//-------------placeholder animation css------------------
.placeholder-wrapper {
  .placeholder {
    margin: 0 auto;
    /* max-width: 200px; */
    min-height: 250px;
    background-color: #eee;
    width: 100%;
    opacity: 0.5;
    .animated-background {
      animation-duration: 1.25s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: darkgray;
      background: linear-gradient(to right, #223d57 5%, #456079 15%, #223d57 50%);
      background-size: 900px 604px;
      height: 450px;
      position: relative;
      @include respond-to("small") {
        height: 310px;
      }
      img {
        position: absolute;
        left: 45%;
        top: 50%;
        opacity: 0.5;
        @include respond-to("medium_lg") {
          left: 42%;
        }
        @include respond-to("medium") {
          left: 38%;
        }
        @include respond-to("small") {
          left: 28%;
        }
      }

      @keyframes placeHolderShimmer {
        0% {
          background-position: -468px 0;
        }
        100% {
          background-position: 468px 0;
        }
      }
    }
  }
  &.cover{
    .animated-background {
      height: 275px;
      @include respond-to("small") {
        height: 160px;
      }
    }
  }
}
