// =========================================
// ========== 404 page styles ==============
// =========================================
.pagenotfound-wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    background: #55BBAC;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  
    .fourOfour {
      object-fit: contain;
      height: 54%;
      object-position: top;
      width: 100%;
      @include respond-to("medium_lg") {
        height: 30%;
      }
    }
    h2 {
      font-size: 24px;
      opacity: 0.72;
      color: #ffffff;
      font-weight: normal;
      margin-top: 30px;
      @include respond-to("small") {
        align-content: center;
        margin: 10px 39px;
        text-align: center;
        font-size: 20px;
      }
    }
    img {
      margin-top: 29px;
    }
    a {
      background-color: #223d57;
      font-size: 18px;
      font-weight: 400;
      padding: 6px 50px;
      display: block;
      color: #ffffff;
      text-decoration: none;
      border-radius: 5px;
      margin-top: 40px;
    }
  }