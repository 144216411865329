// =========================================
// ========== client page styles ===========
// =========================================
.client-wrapper {
  .content-wrapper {
    width: 100%;

    .content-inner-box {
      @include content_wrapper-in;

      width: 70%;
      margin: 80px auto 50px;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
      padding-top: 80px;
      border-radius: 10px;
      position: relative;
      z-index: 9999;
      background: #ffffff;
      @include respond-to("medium_lg") {
        width: 88%;
      }
      @include respond-to("small") {
        width: 90%;
        margin-top: 0;
        border-radius: 0;
      }
      .close-img {
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
      }
      .company-values {
        margin: 0 0px 60px;
        @include respond-to("small") {
          margin: 0 0px 20px;
        }
        .values-title {
          display: flex;
          align-items: center;
          padding-left: 50px;
          @include respond-to("small") {
            padding-left: 30px;
          }

          span {
            width: 6px;
            height: 60px;
            border-radius: 50px;
            background-color: #55bbac;
            @include respond-to("small") {
              height: 40px;
            }
          }
          h1 {
            @include Main_title_In;

            font-size: 60px;
            font-weight: 600;
            letter-spacing: 1px;
            color: #223d57;
            opacity: 0.9;
            margin-left: 16px;
            @include respond-to("small") {
              font-size: 26px;
              margin-left: 10px;
            }
          }
        }
      }
      .card-content-wrapper {
        border-color: #ffffff;
        border-width: 1px;
        .cover-photo {
          @include cover_image_in;
          width: 100%;
          height: 275px;
          object-fit: cover;
          @include respond-to("small") {
            height: 160px;
            object-fit: contain;
            object-position: top;
          }
        }

        .card-content-col {
          display: flex;
          @include respond-to("medium") {
            display: block;
          }
          .image-cap {
            width: 40%;
            background-color: #ffffff;
            margin: -90px 25px 40px;
            padding: 12px;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
            border-radius: 6px;
            position: relative;
            z-index: 99;
            @include respond-to("ex_large_max") {
              width: 45%;
              margin: -90px 0px 16px 16px;
            }
            @include respond-to("medium") {
              width: 90%;
              margin: -90px auto 40px;
              display: flex;
            }
            @include respond-to("small") {
              display: block;
            }
            .profile-image {
              //width: 50%;
              object-fit: cover;
              border-radius: 8px;
              object-position: 0 0;
              box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06);
              @include respond-to("medium_lg") {
                width: 50%;
                height: 50%;
              }
              @include respond-to("medium") {
                width: 200px;
                height: 200px;
              }
              @include respond-to("small") {
                width: 150px;
                height: 150px;
                margin: 0 auto;
                object-fit: contain;
                object-position: center;
              }
            }

            .logo-img-box {
              display: flex;
              justify-content: center;
              margin: 8% auto 0%;
              @include respond-to("medium") {
                flex-direction: column;
                margin: 0;
              }
              @include respond-to("small") {
                flex-direction: column;
                margin: 8% auto 0%;
              }
            }
            .mob-set-inner {
              display: flex;
              flex-direction: column;
            }

            .desc_section {
              padding: 0 15px;
              @include respond-to("small") {
                padding: 0 4px;
              }
              span {
                font-family: Poppins;
                font-size: 52px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: $txtPrimary;
                opacity: 0.4;

                &:nth-child(1) {
                  display: block;
                  margin-left: 25px;
                  margin-top: 25px;
                  margin-bottom: -47px;
                  @include respond-to("small") {
                    transform: rotate(360deg);
                    height: 33px;
                    margin-bottom: 18px;
                    margin-bottom: 0px;
                  }
                }

                &:nth-child(3) {
                  width: 100%;
                  text-align: right;
                  float: right;
                }
              }
              p {
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #000000;
              }
            }

            .name_labels {
              text-align: right;
              padding: 0 15px;

              h4 {
                &:nth-child(1) {
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: right;
                  color: #000000;
                }
                &:nth-child(2) {
                  opacity: 0.6;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 300;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: right;
                  color: #000000;
                }
                &:nth-child(3) {
                  opacity: 0.6;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 300;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: right;
                  color: #000000;
                }
              }
            }
            p {
              font-size: 14px;
              font-weight: 300;
              line-height: 1.5;
              margin-top: 10px;
            }
          }
          .card-discription {
            @include card_discription_in;

            margin: 20px 20px;
            width: 75%;
            @include respond-to("medium") {
              width: 95%;
              padding: 2% 2%;
              margin: 0 auto;
            }
            @include respond-to("small") {
              width: 86%;
              padding: 2% 2%;
              margin: 0 auto;
            }
            .main-head {
              flex: 1 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .heading {
                margin-right: auto;
                @include respond-to("ex_large_max") {
                  margin-right: 0;
                }
                .card-main-title {
                  object-fit: contain;
                  font-size: 24px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.46;
                  letter-spacing: normal;
                  text-align: left;
                  color: #223d57;
                }
                .card-sub-heading {
                  object-fit: contain;
                  font-size: 16px;
                  font-weight: 300;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.56;
                  letter-spacing: normal;
                  text-align: left;
                  color: #000000;
                  @include respond-to("small") {
                    text-align: justify;
                  }
                }
              }
            }

            p {
              object-fit: contain;
              font-size: 14px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              color: #000000;
              padding: 20px 0px 0px;
              @include respond-to("medium_lg") {
                padding: 20% 0 10%;
              }
              @include respond-to("medium") {
                padding: 10% 0 5%;
              }
            }
            .list-items {
              padding: 20px;
              @include respond-to("small") {
                padding: 0;
              }
              ul {
                list-style: none;

                li {
                  object-fit: contain;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: left;
                  color: #000000;
                }
              }
              ul li::before {
                content: "\2022";
                color: #4c4c4c;
                width: 1em;
                font-weight: 900;
                padding: 0px 10px 0px 0px;
                font-size: 150%;
              }
            }
            .list-item-flex {
              display: inline-flex;
              @include respond-to("small") {
                display: block;
                margin-bottom: 30px;
              }
              .list-items-bottom {
                ul {
                  list-style: none;
                  li {
                    margin: 20px 60px;
                    object-fit: contain;
                    font-size: 18px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: left;
                    color: #000000;
                    display: flex;
                    align-items: center;
                    @include respond-to("small") {
                      margin: 10px 60px;
                    }
                  }
                }
                ul li::before {
                  content: "\2022";
                  color: #223d57;
                  font-weight: 900;
                  padding: 0px 4px 0px 0px;
                  font-size: 150%;
                }
                h5 {
                  padding-left: 78px;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: left;
                  color:$primary-color;
                }
              }
            }
          }
          a {
            &.btn-btn-visit {
              @include button_in;

              border-radius: 8px;
              box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
              background-color: #223d57;
              font-size: 14px;
              color: #ffffff;
              border: none;
              display: block;
              outline: none;
              padding: 10px 15px;
              text-decoration: none;
              text-align: center;

              height: 20px;
              @include respond-to("ex_large_max") {
                font-size: 13px;
              }

              &.mobile {
                display: none;
                @include respond-to("medium") {
                  display: block;
                  margin: 13px auto;
                  width: 50%;
                }
                @include respond-to("small") {
                  margin: 13px auto;
                  width: 35%;
                }
              }
              &.desktop {
                display: block;
                width: 30%;
                @include respond-to("ex_large_max") {
                  min-width: 30%;
                  margin-left: 20px;
                }
                @include respond-to("ex_large") {
                  min-width: 27%;
                }
                @include respond-to("medium_lg") {
                  //width: 38%;
                }
                @include respond-to("medium") {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
